import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import ArrowIcon from "../../components/Icons/ArrowIcon";
import Img from "gatsby-image";
import BreakpointUp from "../../components/Media/BreakpointUp";

const Card = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  position: relative;
`;
const CardGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: repeat(1, 1fr);
  ${BreakpointUp.md`  
		grid-template-columns: repeat(3, 1fr);
	`}
`;
const CardTip = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 10px;
`;
CardTip.defaultProps = {
  color: "#2F9800",
  bgColor: "rgba(47, 152, 0, 0.14)",
};

const CardFigure = styled(Link)`
  position: relative;
  width: 100%;
  max-height: 320px;
  height: 100%;
  & .gatsby-image-wrapper {
    height: 100%;
  }
`;
const CardCaption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 1%,
    rgba(2, 3, 4, 1) 99%,
    rgba(2, 3, 4, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 1%,
    rgba(2, 3, 4, 1) 99%,
    rgba(2, 3, 4, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 1%,
    rgba(2, 3, 4, 1) 99%,
    rgba(2, 3, 4, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#002337',GradientType=0 );
  padding: 15px;
  ${BreakpointUp.lg` 
		padding: 20px;	
	`}
`;
const CardTitle = styled(Link)`
  color: #fff;
  font-weight: 700;
  font-family: "Fira Sans", serif;
  font-size: 18px;
  line-height: 28px;
  ${BreakpointUp.lg` 
		font-size:22px;
		line-height:30px;
	`}
`;
const CardArrow = styled(Link)`
  width: 34px;
  height: 34px;
  background-color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #0a4e82;
  }
  &:hover {
    background-color: #0a4e82;
    svg {
      fill: #fff;
    }
  }
`;
const ProductRoofType = ({ data }) => {
  return (
    <CardGrid>
      {data.map((item, i) => {
        return (
          <>
            {item.image && (
              <Card className="card">
                {item.heading.indexOf("Vertical") > -1 ? (
                  <CardTip color="#2F9800" bgColor="#d5eacc">
                    Best
                  </CardTip>
                ) : (
                  <></>
                )}
                {item.heading.indexOf("A-Frame") > -1 ? (
                  <CardTip color="#216ABC" bgColor="#d3e1f2">
                    Better
                  </CardTip>
                ) : (
                  <></>
                )}
                {item.heading.indexOf("Regular") > -1 ? (
                  <CardTip color="#DE5600" bgColor="#f8ddcc">
                    Good
                  </CardTip>
                ) : (
                  <></>
                )}
                {item.image.fluid && (
                  <CardFigure to={item.link}>
                    <Img fluid={item.image.fluid} alt={item.image.title} />
                  </CardFigure>
                )}
                <CardCaption>
                  <CardTitle to={item.link}>{item.heading}</CardTitle>
                  <CardArrow to={item.link}>
                    <ArrowIcon />
                  </CardArrow>
                </CardCaption>
              </Card>
            )}
          </>
        );
      })}
    </CardGrid>
  );
};

export default ProductRoofType;
