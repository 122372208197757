import React from 'react'
import styled from 'styled-components'
import RequestForm from "../../components/RequestForm"
import QuoteFormIcon from "../../components/Icons/QuoteFormIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

const CardForm = styled.div`
	margin-bottom:30px;
  display:flex;
  flex-wrap:wrap;
  background: #fff;
  border:1px solid #E5E5E5;
  width:100%;	
  box-shadow:0 8px 20px rgba(0,0,0,0.05);
`
const CardHeading = styled.div`
  background-color:#F4F8FC;
  padding:25px 30px;
  width:100%;
`
const CardTitle = styled.div`  
  font-size: 20px;
  line-height: 30px;
  font-weight:700;
  color:#002337;
  display:flex;
  align-items:center;
  .icon{
    + .text{
      margin-left:20px;
    }
  }
  
`
const CardBody = styled.div`
  padding:30px 15px;
  width:100%;	
  ${BreakpointUp.sm`				
    padding:30px;  
  `}
  ${BreakpointUp.xl`				
    padding:40px 30px;  
  `}  
`

const ProductForm = () => {
	return(
		<CardForm className="isSticky">
      <CardHeading>
				<CardTitle>
          <span className="icon">
            <QuoteFormIcon />
          </span>
          <span className="text">To Request A Quote, Fill Out Our Short Form.</span>
        </CardTitle>
      </CardHeading>
			<CardBody>
        <RequestForm />
			</CardBody>
		</CardForm> 		
	)
	
}

export default ProductForm