import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import ArrowIcon from "../../components/Icons/ArrowIcon";
import { ExploreMore } from "../../components/section";
import DefaultButton from "../../components/button/DefaultButton";
import BreakpointUp from "../../components/Media/BreakpointUp";
import generateHTML from "../../utils/generateHTML";
import Content, { HTMLContent } from "../../components/Content";

const SectionWrap = styled.div``;
const SectionItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  padding: 20px 0;
  ${BreakpointUp.lg`        
    padding:30px 0;
    margin:0 -20px;
  `}
  ${BreakpointUp.xl`        
    padding:50px 0;
    margin:0 -45px;
  `}
  &:nth-child(even) {
    ${BreakpointUp.lg`        
        flex-direction: row-reverse;
      `}
  }
`;

const SectionFigure = styled.div`
  width: 100%;
  position: relative;
  padding: 0 15px 30px 15px;
  ${BreakpointUp.lg`        
    padding:0 20px;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`  
    padding: 0 45px;
	`}
`;

const SectionContent = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`        
    padding:0 20px;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`  
    padding: 0 45px;
	`}
    & h3 {
    margin-bottom: 15px;
    color: #002337;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const CardFigure = styled.div`
  position: relative;
  width: 100%;
  & .gatsby-image-wrapper {
    height: 100%;
  }
`;
const CardTip = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  padding: 5px 15px;
  position: absolute;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  z-index: 1;
  top: 50px;
`;
CardTip.defaultProps = {
  color: "#2F9800",
  bgColor: "rgba(47, 152, 0, 0.14)",
};

const ProductRoofStyle = ({ data }) => {
  const PostContent = HTMLContent || Content;
  return (
    <SectionWrap>
      {data.map((item, i) => {
        return (
          <SectionItem>
            <SectionFigure className="SectionFigure">
              {item.image && (
                <Card className="card">
                  {item.heading.indexOf("Vertical") > -1 ? (
                    <CardTip color="#2F9800" bgColor="#d5eacc">
                      Best
                    </CardTip>
                  ) : (
                    <></>
                  )}
                  {item.heading.indexOf("A-Frame") > -1 ? (
                    <CardTip color="#216ABC" bgColor="#d3e1f2">
                      Better
                    </CardTip>
                  ) : (
                    <></>
                  )}
                  {item.heading.indexOf("Regular") > -1 ? (
                    <CardTip color="#DE5600" bgColor="#f8ddcc">
                      Good
                    </CardTip>
                  ) : (
                    <></>
                  )}
                  {item.image.fluid && (
                    <CardFigure>
                      <Img fluid={item.image.fluid} />
                    </CardFigure>
                  )}
                </Card>
              )}
            </SectionFigure>
            <SectionContent>
              <h3>{item.heading}</h3>
              <PostContent
                content={generateHTML(
                  item.description.childMarkdownRemark.html
                )}
              />
              <ExploreMore justifyContent="flex-start">
                <Link to={item.link ? `${item.link}` : "/"}>
                  <DefaultButton
                    icon={<ArrowIcon />}
                    text={item.linkText}
                  ></DefaultButton>
                </Link>
              </ExploreMore>
            </SectionContent>
          </SectionItem>
        );
      })}
    </SectionWrap>
  );
};

export default ProductRoofStyle;
