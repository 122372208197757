import React from "react";
import {
  Section,
  SectionTitle,
  SectionDescription,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/section";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import ProductRoofHero from "../sections/Product/ProductRoofHero";
import ProductRoofStyle from "../sections/Product/ProductRoofStyle";
import CategoryLandingProducts from "../sections/Product/CategoryLandingProducts";
import ProductForm from "../sections/Product/ProductForm";
import generateHTML from "../utils/generateHTML";
import Content, { HTMLContent } from "../components/Content";

const ProductCategoryLanding = ({ data }) => {
  const pageData = data.contentfulProductCategoryLanding;
  const PostContent = HTMLContent || Content;
  const productList = data.allContentfulProduct;
  const productsData = [];
 
  productList.edges.forEach(function (item) {
    if (
      pageData.category.name === "Metal Carports" ||
      pageData.category.name === "Metal Garages" ||
      pageData.category.name === "Metal Barns" ||
      pageData.category.name === "RV Carports"
    ) {
      if (pageData.category.name === item.node.productCategory.name) {
        productsData.push(item);
      }
    } else {
      pageData.productSubCategory.forEach((linkSub) => {
        if (item.node.productSubCategory && item.node.productSubCategory.find((sub) => sub.id === linkSub.id)) {
          if (productsData.includes(item) == 0) {
            productsData.push(item);
          }
        }
      });
    }
  });

  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      
      <ProductRoofHero
        title={pageData.heroTitle}
        description={pageData.heroDescription}
        roofs={pageData.supportedRoof}
      />
      <CategoryLandingProducts
        title={pageData.productListSectionTitle}
        description={pageData.productListSectionDescription}
        productsData={productsData}
        category={pageData.category.name}
      />
      
      {pageData.supportedRoof && (
        <Section pt="70px" pb="35px" bg="#F4F8FC" bdrBottom="#ddd">
          <div className="container">
            <SectionTitle maxWidth="1120px">
              {pageData.lastSectionTitle}
            </SectionTitle>
            {pageData.lastSectionDescription && (
              <SectionDescription maxWidth="1120px">
                {pageData.lastSectionDescription.lastSectionDescription}
              </SectionDescription>
            )}
            <ProductRoofStyle data={pageData.supportedRoof} />
          </div>
        </Section>
      )}
      <Section pt="90px" pb="90px" bg="#fff">
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <MarkdownContent>
                <PostContent
                  content={generateHTML(
                    pageData.content.childMarkdownRemark.html
                  )}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <ProductForm />
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
    </Layout>
  );
};

export default ProductCategoryLanding;

export const pageQuery = graphql`
  query ProductCategoryLandingQuery($id: String!) {
    contentfulProductCategoryLanding(id: { eq: $id }) {
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      category {
        name
      }
      productSubCategory {
        id
      }
      supportedRoof {
        heading
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          fluid(maxHeight: 400) {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        linkText
        link
      }
      productListSectionTitle
      productListSectionDescription
      lastSectionTitle
      lastSectionDescription {
        lastSectionDescription
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(sort: { fields: totalPrice }) {
      edges {
        node {
          productName
          image {
            title
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          popularLabel
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
          popularLabel
          url
          productCategory {
            name
          }
          productSubCategory {
            id
          }
        }
      }
    }
  }
`;
