import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import PhoneIcon from "../../components/Icons/PhoneIcon";
import QouteIcon from "../../components/Icons/QouteIcon";
import DefaultButton from "../../components/button/DefaultButton";
import DefaultOutlineButton from "../../components/button/DefaultOutlineButton";
import { BreadCrumb } from "../../components/section";
import ProductRoofType from "../../components/ProductRoofType";
import BreakpointUp from "../../components/Media/BreakpointUp";
import generateHTML from "../../utils/generateHTML";
import Content, { HTMLContent } from "../../components/Content";
import QuotePopup from "../../components/QuotePopup";

const ProductRoofSection = styled.div`
  padding-top: 110px;
  padding-bottom: 40px;
  background: #f4f8fc;
  ${BreakpointUp.lg`    
    padding-top: 150px;
  `}
  & h1 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
`;
const TextSection = styled.div`
  margin: 0 -15px 30px -15px;
  ${BreakpointUp.lg`        
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: space-between;
  `}
`;
const TextContent = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`  
		flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
  & a{
		color:#002337;
		font-weight: 500;
		&:hover, &:focus{
			color:#f99207;
		}
	}
`;
const ButtonWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
		text-align:right;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
	`}
  & a {
    display: block;
    + div {
      margin-top: 20px;
    }
  }
  .btn {
    width: 100%;
    ${BreakpointUp.sm`
      max-width: 248px;
    `}
  }
  ${BreakpointUp.sm`
    display: flex;
    align-items: center;
  `}
  a {
    + div {
      ${BreakpointUp.sm`
        margin: 0 10px;
      `}
    }
  }
`;

class ProductRoofHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    };
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }
  render() {
    const { isVisibleQuote } = this.state;
    const { title, description, roofs } = this.props;
    const PostContent = HTMLContent || Content;
    return (
      <>
        <section>
          <ProductRoofSection>
            <div className="container">
              <BreadCrumb>
                <Link to="/">Home</Link> / <span>{title}</span>
              </BreadCrumb>
              <TextSection>
                <TextContent>
                  <h1>{title}</h1>
                  <PostContent
                    content={generateHTML(description.childMarkdownRemark.html)}
                  />
                </TextContent>
                <ButtonWrap>
                  <a href="tel:8773505464">
                    <DefaultOutlineButton
                      icon={<PhoneIcon />}
                      text="(877) 350-5464"
                    />
                  </a>
                  <div
                    role="button"
                    tabIndex={0}
                    className="quote"
                    onClick={this.showQuote}
                    onKeyDown={this.showQuote}
                  >
                    <DefaultButton
                      icon={<QouteIcon />}
                      text="Request A Quote"
                    />
                  </div>
                </ButtonWrap>
              </TextSection>
            </div>
            {roofs && (
              <div className="container">
                <ProductRoofType data={roofs} />
              </div>
            )}
          </ProductRoofSection>
        </section>
        <QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
      </>
    );
  }
}

export default ProductRoofHero;
