import React from "react";
import FormButton from "../button/FormButton";
import axios from "axios";
import { navigate } from "gatsby";
import { FormControlLabel } from "../section";

class RequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      email: "",
      mobile_no: "",
      comment: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const data = {
      first_name: this.state.first_name,
      email: this.state.email,
      mobile_no: this.state.mobile_no,
      comment: this.state.comment,
    };
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.FORM_CRM_TOKEN },
    })
      .then((res) => {
        this.setState({
          first_name: "",
          email: "",
          mobile_no: "",
          comment: "",
        });
        navigate("/thank-you-for-request");
      })
      .catch((error) => {
        alert(error);
      });
  }
  render() {
    return (
      <form id="rightform" onSubmit={this.handleSubmit}>
        <div className="form-group-bordered">
          <input
            type="text"
            id="first_name"
            className="form-control"
            onChange={this.handleChange}
            required
            placeholder="Full Your Name"
          />
          <FormControlLabel
            className={
              this.state.first_name !== ""
                ? "control-label active"
                : "control-label"
            }
          >
            Full Your Name
          </FormControlLabel>
        </div>
        <div className="form-group-bordered">
          <input
            type="tel"
            id="mobile_no"
            className="form-control"
            onChange={this.handleChange}
            pattern="[0-9]{10}"
            required
            placeholder="Mobile Number"
          />
        </div>
        <div className="form-group-bordered">
          <input
            type="email"
            id="email"
            className="form-control"
            onChange={this.handleChange}
            required
            placeholder="Email Address"
          />
        </div>
        <div className="form-group-bordered">
          <textarea
            id="comment"
            className="form-control"
            onChange={this.handleChange}
            required
            placeholder="Ask Your Query"
          />
        </div>
        <div className="form-action">
          <FormButton text="Submit" />
        </div>
      </form>
    );
  }
}

export default RequestForm;
