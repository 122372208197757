import React, { useState } from "react";
import styled from "styled-components";
import BreakpointUp from "../../components/Media/BreakpointUp";
import {
  Section,
  SectionTitle,
  SectionDescription,
} from "../../components/section";
import ProductCardList from "../../components/TabProductCardList";
import RequestQuote from "../../components/RequestQuote"
import QuotePopup from "../../components/QuotePopup";
import DefaultButton from "../../components/button/DefaultButton";
import {ExploreMore} from "../../components/section";

const Nodata = styled.div`
  text-align: center;
  padding: 15px 0;
  ${BreakpointUp.lg`
		padding:30px 0;
	`}
`;
const NodateContent = styled.div`
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
`;
const NodateText = styled.div`
  & p {
    margin-bottom: 0;
    font-weight: 500;
    ${BreakpointUp.lg`
			font-size:20px;
			line-height:30px;
		`}
  }
`;
class CategoryLandingProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    };
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }
  render() {
    const { isVisibleQuote } = this.state;
    const { title, description, productsData, category } = this.props;

    return (
      <>
        <Section pt="70px" pb="70px" xpt="60px" xpb="60px" bdrBottom="#F4F8FC">
          <div className="container">
            <SectionTitle maxWidth="1120px">{title}</SectionTitle>
            <SectionDescription maxWidth="1120px">
              {description}
            </SectionDescription>
            {productsData.length > 0 ? (
              <ProductCardList
                category={category}
                data={productsData}
                buttonTxt="Explore all Buildings"
                buttonLink="/products"
                showQuote={this.showQuote}
              />
            ) : (
              <Nodata>
                <NodateContent>
                  <NodateText>
                    <p>
                      Currently we have not added any product in this category
                    </p>
                  </NodateText>
                </NodateContent>
              </Nodata>
            )}
          </div>
        </Section>
        <Section pt="0" pb="0"><RequestQuote ctaTitle="Looking For a Metal Building Which is Specially Designed for You?" ctaDesc="We Have Exactly the One You Need, Connect With Our Experts." /></Section>
        <QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
      </>
    );
  }
}

export default CategoryLandingProducts;
